import React, { useEffect, useState } from 'react';
import ChatBot from './avatar';
import { useAuth } from '../auth/authContext';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { doSignOut } from '../auth/firebase/auth';
import { useNavigate } from 'react-router-dom';

const PhoneVerificationPage = () => {
    const [phoneNumberVerified, setPhoneNumberVerified] = useState(false);
    const [loading, setLoading] = useState(true);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneCode, setPhoneCode] = useState('+91');
    const [otpValue, setOtpValue] = useState('');
    const [otpSentToUser, setOtpSentToUser] = useState('0000');
    const [sendingOtp, setSendingOtp] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [phoneErrorMessage, setPhoneErrorMessage] = useState('');
    const [otpSendSuccessMessage, setOtpSendSuccessMessage] = useState('');

    const { currentUser } = useAuth();

    let navigate = useNavigate();

    const removePlusSymbol = (code) => {
        if (code.startsWith('+')) {
            return code.substring(1);
        }
        return code;
    };

    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await fetch(`https://dynaptmax-backend-fastapi-wtnegfwvgq-el.a.run.app/helper_routers/get_user_by_email?email=${currentUser.email}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'accept': 'application/json'
                    },
                });
                const data = await response.json();
                
                if(response.ok){
                    setPhoneNumberVerified(true);
                }
                setLoading(false); 
            } catch (error) {
                //console.log(error);
                setLoading(false); 
            }
        };

        fetchData();
    }, []);

    const sendUserInfo = async () =>{
        const phCode = removePlusSymbol(phoneCode);
        const words = currentUser.displayName.split(' ');
        let firstName = words[0];
        let lastName = words.length > 1 ? words.slice(1).join(' ') : 'lastName';
        try {
            const response = await fetch('https://dynaptmax-backend-fastapi-wtnegfwvgq-el.a.run.app/helper_routers/create_user', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
                body: JSON.stringify({ 
                    CreatedBy: 1,
                    FirstName: firstName,
                    LastName: lastName,
                    Email: currentUser.email,
                    MobilePhone: phoneNumber,
                    EncryptedPassword: "Password",
                    PhoneCode: phCode,
                    Organization: "Dynapt"
                }),
            });

        } catch (error) {
            console.log(error);
        }
    };

    if (loading) {
        return <p>Loading...</p>; 
    }
   
       
    
    const sendOtp = (resend) => {
        const phCode = removePlusSymbol(phoneCode);
        if(phCode==''){
            setPhoneErrorMessage('Enter Phone Code');
            return;
        }
        if(phoneNumber==''){
            setPhoneErrorMessage('Enter Phone Number');
            return;
        }
        const phone = parsePhoneNumberFromString('+'+phCode+phoneNumber);
        //console.log(phone);
        if(!phone.isValid()){
            setPhoneErrorMessage('Enter a valid Phone Number');
            return;
        }

        setSendingOtp(true);
        const random = Math.floor(Math.random() * 9000 + 1000);
        setOtpSentToUser(random);
        const reqToSend = {
            "receiver": `${phCode}${phoneNumber}@s.whatsapp.net`,
            "message": { "text": `Your otp is ${random}` }
        };

        fetch('https://wa.dynaptsolutions.com:8000/chats/send?' + new URLSearchParams({
            id: 'device_14',
        }), {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(reqToSend),
        }).then(response => {
            if (!response.ok) {
                throw new Error('Server response was not OK');
            }
            return response;
        })
        .then(() => {
            setOtpSent(true);
            setSendingOtp(false);
            if(!resend){setOtpSendSuccessMessage('OTP resent successfully.')}
            else{setOtpSendSuccessMessage('OTP sent successfully.');}
        }).catch((error) => {
            setSendingOtp(false);
            setPhoneErrorMessage('Failed to send OTP. Please retry.');
            console.log(error);
        })     
    };     
    
    const resendOtp = () =>{
        setPhoneErrorMessage('');
        setOtpValue('');
        sendOtp(false);
    }
                                 
    const verifyOtp = () => {
        setOtpSendSuccessMessage('');
        if(otpValue==''){
            setPhoneErrorMessage("Enter OTP");
            return;
        }
        if(otpValue==otpSentToUser){
        sendUserInfo()
        setPhoneNumberVerified(true);
        }
        else{
            setPhoneErrorMessage("Incorrect OTP");
        }
    };

    return (
        <div className="flex justify-center items-center h-screen">
            {phoneNumberVerified? (
                <ChatBot/>
            ):(
            <div className="max-w-md w-full p-8 bg-white shadow-lg rounded-lg">
                <div>
                            <label className="text-sm text-gray-600 font-bold">Verify your Phone Number</label>
                            <div className="flex items-center mt-2">
                                <input
                                    type="text"
                                    autoComplete="off"
                                    maxLength={4}
                                    placeholder="+"
                                    value={phoneCode}
                                    onChange={(e) => {setPhoneCode(e.target.value);setPhoneErrorMessage('');}}
                                    className="w-1/5 px-2 py-2 text-gray-500 bg-transparent outline-none rounded-l-lg focus:border-indigo-600 shadow-sm transition duration-300 border border-r-0"
                                />
                                <input
                                    type="tel"
                                    autoComplete="tel"
                                    required
                                    value={phoneNumber}
                                    onChange={(e) => {setPhoneNumber(e.target.value);setPhoneErrorMessage('');}}
                                    className="w-4/5 px-3 py-2 text-gray-500 bg-transparent outline-none focus:border-indigo-600 shadow-sm rounded-r-lg transition duration-300 border border-l-0 border-l"
                                />
                                {!phoneNumberVerified && !otpSent && (
                                    <button
                                        type="button"
                                        disabled={sendingOtp}
                                        onClick={sendOtp}
                                        className="ml-2 px-4 py-2 bg-indigo-600 text-white font-medium rounded-lg hover:bg-indigo-700 transition duration-300"
                                    >
                                       {sendingOtp? 'Sending...' : 'Send OTP'} 
                                    </button>
                                )}
                            </div>
                            {otpSent && !phoneNumberVerified && (
                                <div className="mt-2 flex items-center">
                                    <input
                                        type="text"
                                        value={otpValue}
                                        onChange={(e) => {setOtpValue(e.target.value); setPhoneErrorMessage(''); setOtpSendSuccessMessage('');}}
                                        className="w-full px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg transition duration-300"
                                    />
                                    <button
                                        type="button"
                                        onClick={verifyOtp}
                                        className="ml-2 px-4 py-2 bg-indigo-600 text-white font-medium rounded-lg hover:bg-indigo-700 transition duration-300"
                                    >
                                        Verify OTP
                                    </button>
                                    <button
                                        type="button"
                                        onClick={resendOtp}
                                        className="ml-2 px-4 py-2 bg-indigo-600 text-white font-medium rounded-lg hover:bg-indigo-700 transition duration-300"
                                    >
                                        Resend OTP
                                    </button>
                                </div>
                            )}
                            {phoneNumberVerified && (
                                <div className="mt-2 flex items-center">
                                    <span className="text-green-500 mr-2">&#10003;</span>
                                    <span className="text-gray-600">Verified</span>
                                </div>
                            )}
                        </div>
                        {phoneErrorMessage&& <span className="text-red-600 font-bold">{phoneErrorMessage}</span>}
                        {otpSendSuccessMessage && <div className="text-green-500">{otpSendSuccessMessage}</div>}
                        <div className='flex flex-row items-center justify-center text-center w-full py-4'>
                            <div className='border-t-2 flex-grow mt-3 mr-2'></div>
                            <div className='text-sm font-semibold px-2 mt-3'>OR</div>
                            <div className='border-t-2 flex-grow mt-3 ml-2'></div>
                        </div>
                        <div className="text-sm text-center mt-2">
                            Need to use a different account?
                            <button
                                onClick={() => {
                                    doSignOut();
                                    navigate('/');
                                }}
                                className="ml-2 text-sm text-indigo-600 underline font-semibold hover:text-indigo-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                Sign in
                            </button>
                        </div>
            </div>
        
        
        )}
        </div>
    );
};

export default PhoneVerificationPage;
